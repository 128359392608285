<template>
  <div :style="getElementStyle" v-loading="loading">
    <el-row align="middle">
      <el-col
        :span="showLabel && !isDefalutPosq ? 4 : data.description ? 20 : 24"
        :style="computedStyles"
      >
        <label for="text" style="margin-right: 5px; font-weight: bold">{{label ||
          data.label
        }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <!-- <i class="el-icon-copy-document" v-if="!isDataTableField && !isView" @click="copyDocument"></i> -->
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
        <div v-if="!isView">
          <template
            v-if="
              data.integration_settings.selectedIntegrationFieldVariableType ===
              'select'
            "
          >
            <el-select
              v-model="form[data.key]"
              placeholder="Select an option"
              @change="applyFormRules"
              :disabled="checkReadonly()"
              :style="getStyle"
            >
              <el-option
                v-for="(item, index) in integrationVariables"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
          <template
            v-else-if="
              data.integration_settings.selectedIntegrationFieldVariableType ===
              'date'
            "
          >
            <el-date-picker
              v-model="form[data.key]"
              :placeholder="data.placeholder"
              @change="applyFormRules"
              :disabled="checkReadonly()"
              :style="getStyle"
              type="date"
              format="MM-dd-yyyy"
            />
          </template>
          <template
            v-else-if="
              data.integration_settings.selectedIntegrationFieldVariableType ===
              'text'
            "
          >
            <el-input
              v-model="form[data.key]"
              :placeholder="data.placeholder"
              @change="applyFormRules"
              :disabled="checkReadonly()"
              :suffix-icon="data.properties.el_icon"
              :style="getStyle"
            />
          </template>
          <span
            v-if="isActive || isDataTableField"
            class="setting-icon"
            @click="openSettings"
          >
            <i class="el-icon-s-tools" />
          </span>
        </div>
        <div v-else>
          {{ form[this.data.key] }}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { postAPICall } from "../../../helpers/httpHelper";
export default {
  components: {},
  props: [
    "data",
    "label",
    "value",
    "isActive",
    "isDataTableField",
    "isInDataTable",
    "colorFields",
    "form",
    "fromRepeatable",
    "fieldsData",
    "entityDataId",
    "checkIsDisabled",
    "entityId",
    "isHideField",
    "highlight",
    "fromEntityViews",
    "isView",
    "hasLabel",
    "integrationData",
    "filteredIntegrationVariableFields",
    "selectedIntegrationRecord",
  ],
  computed: {
    computedStyles() {
      const styles = {};
      styles.display = "flex";
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    getStyle() {
      return `height:${this.data.height - 30}px;`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
      }
      return borderStyle;
    },
    isDefalutPosq() {
      return this.data.styles && this.data.styles.labelStyle !== "right";
    },
  },
  data() {
    return {
      validations: [],
      options: [],
      showLabel: true,
      integrations: [],
      selectedValue: this.data.key,
      loading: false,
      integrationVariables: [],
      integrationVariablesObj: {},
    };
  },
  watch: {
    integrationData: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.handleFormChange(newVal);
        }
      },
    },
  },
  async mounted() {
    if (!this.isView) {
      await this.fetchIntegrations();
      await this.fetchAllPredefinedIntegrations();
      await this.getRequiredData();
    }
  },
  methods: {
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "DISABLED"
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    openSettings() {
      this.$emit("settings");
    },
    async fetchIntegrations() {
      try {
        let params = {
          limit: 10,
          page: 1,
        };
        let integrationData = await postAPICall("GET", `integrations`, params);
        this.integrations = integrationData.data;
        return this.integrations;
      } catch (err) {
        return err;
      }
    },
    async fetchAllPredefinedIntegrations() {
      const response = await postAPICall(
        "GET",
        "integrations/service/get-all-applications"
      );
      this.predefinedIntegrations = response?.data || [];
    },
    async handleFormChange() {
      try {
        let allIntegrationVariables = this.filteredIntegrationVariableFields;
        let matchedVaribleField = allIntegrationVariables.find(
          (varibleField) => varibleField.key == this.data.key
        );
        if (matchedVaribleField) {
          let linkedColumn =
            this.data.integration_settings["selectedIntegrationFieldVariable"];
          let value =
            this.data.integration_settings
              .selectedIntegrationFieldVariableType == "select"
              ? this.integrationVariablesObj[
                  this.selectedIntegrationRecord[`${linkedColumn}`]
                ]
              : this.selectedIntegrationRecord[`${linkedColumn}`];
          this.$set(this.form, this.data.key, value);
        }
      } catch (err) {
        return err
      }
    },
    async getRequiredData() {
      try {
        let integrationField =
          this.data.integration_settings.selectedIntegrationField;
        let integrationFieldDetails = this.fieldsData.find(
          (field) => field.key == integrationField
        );
        let event = integrationFieldDetails.integration_settings.selectedEvent;
        let applicationSelected =
          integrationFieldDetails.integration_settings.selectedApplication;
        let application = this.predefinedIntegrations.find(
          (selectedApp) => selectedApp._id === applicationSelected
        );
        let app = application.key;
        let eventsArray = application.events;
        let integratioEvent = eventsArray.find(
          (selectedEvent) => String(selectedEvent._id) === event
        );
        let variableEvent = eventsArray.find(
          (event) => event.asset === integratioEvent.settings.eachRecordByEvent
        );
        let connection;
        if (integrationFieldDetails.integration_settings.selectedIntegration) {
          connection =
            integrationFieldDetails.integration_settings.selectedIntegration;
        } else {
          let templateDependencies =
            integrationFieldDetails.integration_settings.templateDependencies;
          templateDependencies.forEach((dep) => {
            let fieldData = this.fieldsData.find((field) => field.key === dep);
            if (fieldData.integration_settings.selectedIntegration) {
              connection = fieldData.integration_settings.selectedIntegration;
            }
          });
        }
        if (
          variableEvent &&
          variableEvent.settings &&
          variableEvent.settings.availableColumns
        ) {
          let allColumns = variableEvent.settings.availableColumns;
          let columnFound = allColumns.find(
            (selectedColumn) =>
              String(selectedColumn.key) ===
              this.data.integration_settings.selectedIntegrationFieldVariable
          );
          if (columnFound && columnFound.isApiData) {
            let requiredData = {};
            let detailKey = columnFound.requiredAsset;
            let requestBody = {
              selectedApplication: app,
              selectedEvent: detailKey,
              selectedIntegration: connection,
              requiredFields: requiredData,
            };
            let response = await this.fetchRequiredFieldData(requestBody);
            this.integrationVariables = response.map((variable) => {
              this.integrationVariablesObj[variable.id] = variable.name;
              return {
                label: variable.name,
                value: variable.id,
              };
            });
          }
        }
      } catch (err) {
        console.log("errr", err);
      }
    },
    async fetchRequiredFieldData(payload) {
      try {
        const provider = payload.selectedApplication;
        const requestBody = {
          provider,
          field: payload.selectedEvent,
          formData: payload.requiredFields,
        };
        const response = await postAPICall(
          "POST",
          `integrations/service/get-requiredFields-data/${payload.selectedIntegration}`,
          requestBody
        );
        const data = response?.data || {};
        return data;
      } catch (error) {
        return error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;

  .el-input,
  .el-input__inner {
    height: inherit !important;
  }
}
</style>
